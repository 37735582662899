@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@400;500;700;900&display=swap');

body {
    overflow-x: hidden;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

a {
    text-decoration: none;
}

body::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
}

.home-wrap {
    background-image: url('../../public/image/background.png');
    width: 100vw;
    height: fit-content;
    min-height: 100vh;
    background-size: cover;

    .title-wrap {
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .logo {
            width: 320px;
            height: 73px;
        }

        span {
            color: white;
            font-family: 'Mine-Reg';
            font-size: 80px;
        }

        div {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 15px;

            .select-wrap {
                border: none;

                select {
                    outline: none;
                    background-color: transparent;
                    border: none;
                    color: white;
                    font-weight: 800;
                    font-size: 14px;
                }

                option {
                    background: black;
                }
            }

            div {
                width: fit-content;
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;
                border-bottom: 2px solid white;
                font-weight: 800;
                font-size: 18px;
                margin: 0 3px 0 3px;

                input {
                    border: none;
                    background-color: transparent;
                    outline: none;
                    font-weight: 800;
                    font-size: 20px;
                    text-align: right;
                    color: white;
                    width: 24px;
                }

                .student-no {
                    width: 30px;
                }

                .player-name {
                    width: 150px;
                    text-align: right;
                    font-size: 16px;
                    padding-right: 8px;

                    &::placeholder {
                        color: rgba(255, 255, 255, 0.5)
                    }
                }

                .email {
                    width: 210px;
                    font-size: 16px;
                    text-align: center;
                }
            }
        }

        .submit {
            border: none;
            background-color: white;
            color: black;
            font-size: 15px;
            width: 140px;
            height: 28px;
            cursor: pointer;
            border-radius: 1000px;
            margin-left: 10px;
            margin-top: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 600;
            font-family: 'Mine-Reg';
            padding-top: 5px;
            letter-spacing: 1.5px;
        }

        button {
            margin-top: 20px;
            border: none;
            background-color: transparent;
            color: white;
            font-weight: 700;
            font-size: 16px;
            cursor: pointer;
        }

        span {
            margin-left: 10px;
        }
    }
}

.content {
    width: auto;
    height: fit-content;
    display: flex;
    flex-wrap: wrap;
    font-family: 'Noto Sans KR', sans-serif;
    background-color: #101010;
    color: white;
    padding-left: 15%;

    .wrap {
        padding: 7rem 0 5rem 5rem;

        h1 {
            margin-top: 10px;
            margin-bottom: 2rem;
            font-size: 3.3rem;
        }

        p {
            color: #FFF;
            opacity: 0.75;
            font-size: 1.2rem;
        }

        .horizontal-line {
            margin-top: 2rem;
            margin-bottom: 24px;
            display: flex;
            align-items: center;

            button {
                border: 0;
                width: 266px;
                height: 30px;
                border-radius: 5rem;
                line-height: 0;
                vertical-align: middle;
                text-align: center;
                background-color: #3734e9;
                padding: 2rem;
                color: white;
                font-size: 1.5rem;
                font-weight: 500;
                display: flex;
                align-items: center;
            }
            .download-icon {
                margin-right: 5px;
            }
            button:hover {
                background-color: #1d2ae2;
                cursor: pointer;
            }
            .description {
                margin-left: 25px;
                font-size: 1.2rem;
                font-weight: 400;
            }
        }

    }

}

.pin-wrap {
    height: 60px;

    .pin-input {
        margin-top: 15px;
        border: none;
        border-bottom: 2px solid white;
        outline: none;
        background-color: transparent;
        color: white;
        font-weight: 800;
        text-align: center;
        font-size: 18px;
        width: 120px;

        &::placeholder {
            font-size: 14px;
        }
    }

    button {
        margin-left: 10px;
    }
}